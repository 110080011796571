export enum Sites {
  twitter = "TWITTER",
  reddit = "REDDIT",
  youtube = "YOUTUBE",
}

export enum Redirect {
  onboarding = "ONBOARDING",
  preferences = "PREFERENCES",
}
